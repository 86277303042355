<template>
  <div class="layout-px-spacing dash_1">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"><span>Licitações</span></li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="row layout-top-spacing">

      <div class="alert alert-icon-left alert-dismissible alert-light-success mb-4" role="alert" v-if="msgLogin != '' && !msgLogin.includes('Erro')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>
        <strong>ATENÇÃO!</strong> {{ msgLogin }}
        <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
      </div>
      <div class="alert alert-icon-left alert-dismissible alert-light-danger mb-4" role="alert" v-if="msgLogin != '' && msgLogin.includes('Erro')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
        <strong>ATENÇÃO!</strong> {{ msgLogin }}
        <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
      </div>

      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Licitações as últimas 24Hrs</h5>
          </div>
          <vue-element-loading :active="isLoadingBars" spinner="bar-fade-scale" />
          <div class="widget-content">
            <apex-chart v-if="!isLoadingBars" height="460" type="donut" :options="sales_donut_options"
              :series="sales_donut_series"></apex-chart>
          </div>
        </div>
      </div>

      <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-top-selling-products">
          <div class="widget-heading">
            <h5>Últimas licitações adicionadas</h5>
          </div>
          <vue-element-loading :active="isLoadingLista" spinner="bar-fade-scale" />

          <div class="widget-content table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="th-content">Portal</div>
                  </th>
                  <th>
                    <div class="th-content th-heading">Objeto</div>
                  </th>
                  <th>
                    <div class="th-content th-heading">Publicação</div>
                  </th>
                  <th>
                    <div class="th-content">Disputa</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="datalics" v-for="reg in datalics" :key="reg.id">
                  <td>
                    <div class="td-content"><span class="pricing">{{ reg.nomeportal }}</span></div>
                  </td>
                  <td>
                    <div class="td-content"><span class="pricing">{{ reg.objeto }}</span></div>
                  </td>
                  <td>
                    <div class="td-content"><span class="discount-pricing">{{ formataData(reg.datapublicacao) }}</span></div>
                  </td>
                  <td>
                    <div class="td-content">{{ formataDataHora(reg.datadisputa) }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/sass/widgets/widgets.scss';
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ApexChart from 'vue3-apexcharts';
import connServ from '@/api/conserv';
import { showAlert, formataData, formataDataHora } from '@/mixins/fcgeral';
import VueElementLoading from 'vue-element-loading';
import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Novas licitações' });

const router = useRouter();
const store = useStore();

const show_wait = ref(false);
const datalics = ref(null);
const values_lics = ref([]);
const labs_lics = ref([]);
const colors_lics = ref([]);
const isLoadingBars = ref(true);
const isLoadingLista = ref(true);
const is_dark = computed(() => store.state.is_dark_mode);
const userDados = ref(store.getters.userDados);
const msgLogin = ref('');


// Sales by Category
const sales_donut_series = ref([]);
const sales_donut_options = ref({});

const updateDonutChartOptions = () => {
  sales_donut_options.value = {
    chart: {
      type: 'donut',
    },
    dataLabels: { enabled: true },
    expandOnClick: !is_dark.value,
    colors: is_dark.value ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : colors_lics.value,
    legend: {
      position: 'right',
      horizontalAlign: 'center',
      fontSize: '12px',
      markers: { width: 10, height: 10 },
      height: 150,
      offsetY: 20,
      itemMargin: { horizontal: 8, vertical: 0 },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: { show: true, fontSize: '29px', fontFamily: 'Nunito, sans-serif', offsetY: -5 },
            value: {
              show: true,
              fontSize: '26px',
              fontFamily: 'Nunito, sans-serif',
              color: is_dark.value ? '#bfc9d4' : undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: 'Total',
              color: '#888ea8',
              fontSize: '29px',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce(function (a, b) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    labels: labs_lics.value,
  };

  if (is_dark.value) {
    sales_donut_options.value.states = {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    };
  }

  console.log('sales_donut_options', sales_donut_options.value);
};


async function ListaLicitacoes24H() {
  let json = await connServ('api/DashLics/ListaLics24H', 'get', '', router);
  if (json.bstatus && json.dados.status) {
    let vals = json.dados.registro.map((a) => a.total);
    let labs = json.dados.registro.map((a) => a.nomeportal);
    let cores = json.dados.registro.map((a) => a.cor);

    // Certifique-se de que são arrays
    colors_lics.value = Array.isArray(cores) ? cores : [];
    values_lics.value = Array.isArray(vals) ? vals : [];
    labs_lics.value = Array.isArray(labs) ? labs : [];

    // Atualiza as séries e as opções do gráfico
    sales_donut_series.value = values_lics.value;
    updateDonutChartOptions();

    isLoadingBars.value = false;

    console.log('colors_lics = ', JSON.stringify(colors_lics.value));
    console.log('values_lics = ', JSON.stringify(values_lics.value));
    console.log('labs_lics = ', JSON.stringify(labs_lics.value));

  } else {
    showAlert('error', json.msg);
  }
}

async function ListaUltimasPortais() {
  show_wait.value = true;
  let json = await connServ('api/DashLics/ListaUltimasLics', 'get', '', router);
  if (json.bstatus && json.dados.status) {
    datalics.value = [...json.dados.registro];
    isLoadingLista.value = false;
  } else {
    showAlert('error', json.msg);
  }
  show_wait.value = false;
}

onMounted(async () => {
  await ListaLicitacoes24H();
  await ListaUltimasPortais();
  if (userDados.value.msgAddLogin != "") {
    msgLogin.value = userDados.value.msgAddLogin;
    userDados.value.msgAddLogin = "";
    store.commit('changeUserDados', userDados.value);
  }
});

</script>

<style scoped></style>
