<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-bind:is="layout"></component>

        <BackToTop />
    </div>
</template>
<script setup>
    import { computed } from 'vue';
    import '@/assets/sass/app.scss';
    import { useMeta } from '@/composables/use-meta';
    import { useStore } from 'vuex';
    import BackToTop from './components/layout/BackToTop.vue';

    useMeta({ title: 'Lance Fácil' });

    const store = useStore();

    const layout = computed(() => {
        return store.getters.layout;
    });

    loadMessages(ptMessages);
    locale(navigator.language);

</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';

    import ptMessages from 'devextreme/localization/messages/pt.json';
    import { locale, loadMessages } from 'devextreme/localization';


    export default {
        components: {
            app: appLayout,
            auth: authLayout,
        },
    };
</script>