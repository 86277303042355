import axios from 'axios';
import { useRouter } from 'vue-router';

const isProduction = process.env.NODE_ENV === 'production';

const api = axios.create({
    //baseURL: 'https://apiuser.lancefacil.com/'
    //baseURL: 'https://localhost:44322/'
    baseURL: isProduction ? 'https://apiuser.lancefacil.com/' : 'https://localhost:44322/'
});

api.interceptors.request.use(config => {
    return config
}, error => Promise.reject(error))

api.interceptors.response.use(res => {
    return res
}, error => Promise.reject(error))

export default async function connServ(url, tpreq, dados, router) {
    //corrigindo headers
    let responseApi = { bstatus: false, dados: {}, msg: "" };
    var xTokenAuth = '';
    let user = JSON.parse(localStorage.getItem('userDados'));

    if (user) xTokenAuth = user.token;

    api.defaults.headers.common['Authorization'] = 'Bearer ' + xTokenAuth;
    api.defaults.headers.common['Content-Type'] = 'application/json';
    api.defaults.headers.common['Access-Control-Expose-Headers'] = '*';

    if (tpreq === 'get') {
        await api.get(url).then((response) => {
            responseApi.bstatus = true;
            responseApi.dados = response.data;
        }).catch((error) => {
            console.log('ERRO GET: ', error);
            if (error.message === 'Network Error') {
                responseApi.bstatus = false;
                responseApi.msg = 'Não foi possível conectar ao servidor!';
                return;
            }
            responseApi.bstatus = false;
            responseApi.msg = error.message;
            if (error.response) {
                if (error.response.status === 401) {
                    responseApi.msg = "Faça a autenticação novamente!";
                    router.push('/auth/logout');
                }
            }
        });
    }

    if (tpreq === 'post') {
        console.log("Postando em ", dados, " ", url)
        await api.post(url, dados).then((response) => {
            responseApi.bstatus = true;
            responseApi.dados = response.data;
        }).catch((error) => {
            console.log('ERRO POST: ', JSON.stringify(error));
            //console.log('ERRO RESPONSE: ', JSON.stringify(error.response.data.message));
            if (error.message === 'Network Error') {
                responseApi.bstatus = false;
                responseApi.msg = 'Não foi possível conectar ao servidor: ' + error.message;
                return;
            }
            responseApi.bstatus = false;
            responseApi.msg = error.message;
            if (error.response) {
                if (error.response.status === 401) {
                    responseApi.msg = "Faça a autenticação novamente!";
                    router.push('/auth/logout');
                }
                if(error.response.data.message)
                    responseApi.msg = error.response.data.message;
                else
                    responseApi.msg = "Ocorreu um erro na operação, tente novamente ou contate o suporte técnico!";
                if(error.config.url)
                    responseApi.msg += "<br />" + error.config.url;
            }
        });
    }

    if (tpreq === 'postFile') {
        api.defaults.headers.common['Content-Type'] = 'multipart/form-data';

        await api.post(url, dados).then(res => {
            responseApi.bstatus = true;
            responseApi.dados = res.data;
        }).catch(err => {
            responseApi.bstatus = false;
            responseApi.msg = err.message;
        });
    }

    if (tpreq === 'down') {
        console.log("download em ", dados, " ", url)
        await api.get(url, { responseType: 'blob' }).then((response) => {
            if (responseApi.bstatus) {
                responseApi.bstatus = true;
                responseApi.dados = response.data;
            }

            const blob = new Blob([response.data], { type: response.data.type });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;

            // Obtenha o nome do arquivo do cabeçalho Content-Disposition
            const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'] || '';
            console.log("contentDisposition = ", contentDisposition);
            let fileName = dados;
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch && fileNameMatch[1]) {
                    fileName = fileNameMatch[1];
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

        }).catch((error) => {
            responseApi.bstatus = false;
            responseApi.msg = error.message;
        });
    }
    
    return responseApi;
}
